import React, { useState } from "react"
import { openModal } from "../services/modal"
import Button from "./button"
import styles from "./getStarted.module.scss"

const GetStarted = ({ languageCode, translations }) => (
  <div className={styles.wrapper}>
    <h2>{translations.time_to_get_started}</h2>
    <Button onClick={openModal} classStyle={styles.button}>
      BOOK A MEETING
    </Button>
  </div>
)

export default GetStarted
