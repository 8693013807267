import React from "react"

import Layout from "../components/layout"
import { Link, graphql } from "gatsby"
import globalStyles from "../services/globalStyles.module.scss"
import Header from "../components/header"
import Footer from "../components/footer"
import styles from "./404.module.scss"
import GetStarted from "../components/getStarted"
import notFoundSvg from "../images/not-found.svg"
import { ArrowRightBlue } from "../components/svgIcons"
class NotFoundPage extends React.Component {
  render() {
    const {
      texts: { _ },
      staffContent,
    } = this.props.data
    const languageCode =
      this.props.pageContext && this.props.pageContext.lang
        ? this.props.pageContext.lang
        : "en"

    return (
      <Layout
        header={
          <Header
            num={1}
            translations={_}
            headerFullYOffset={600}
            mobileHeaderFullYOffset={40}
            fixed
            languageCode={languageCode}
          />
        }
        translations={_}
        languageCode={languageCode}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <div>
              <h1 className={styles.notFoundHeader}> 404 error </h1>
              <p className={styles.notFoundLost}>We lost that page</p>
              <p className={styles.notFoundText}>
                Sorry, the page you ar looking for doesn't exist or has been
                moved. <br /> Here are some links that may help:
              </p>
              {/* FIXME: to be discussed if it should be here */}
              {/* <div className={styles.inputWrapper}>
                <SearchIcon />
                <input
                  className={styles.input}
                  placeholder="Search our site.."
                />
              </div> */}
              <Link
                href={`https://sidelinesports.com/${
                  languageCode === "cs" ? "cs/" : ""
                }features`}
                target="_blank"
                label="FEATURES"
                className={styles.link}
              >
                <div className={styles.linkTitle}>
                  Features
                  <ArrowRightBlue />
                </div>
                <div className={styles.linkDescription}>
                  Learn all about XPS Network
                </div>
              </Link>
              <Link
                href={`https://sidelinesports.com/${
                  languageCode === "cs" ? "cs/" : ""
                }blog`}
                target="_blank"
                label="BLOG"
                className={styles.link}
              >
                <div className={styles.linkTitle}>
                  Our Blog
                  <ArrowRightBlue />
                </div>
                <div className={styles.linkDescription}>
                  News from the world of sports & technology
                </div>
              </Link>
              <Link
                href={`https://sidelinesports.com/${
                  languageCode === "cs" ? "cs/" : ""
                }support`}
                target="_blank"
                label="SUPPORT"
                className={styles.link}
              >
                <div className={styles.linkTitle}>
                  Support
                  <ArrowRightBlue />
                </div>
                <div className={styles.linkDescription}>
                  Get help with XPS Network
                </div>
              </Link>
            </div>
            <div>
              <img
                className={styles.notFoundImage}
                src={notFoundSvg}
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <GetStarted languageCode={languageCode} translations={_} />
        <Header
          num={2}
          className={globalStyles.hideOnMobile}
          translations={_}
          headerFullYOffset={600}
          boxShadow
          languageCode={languageCode}
          wrapperStyles={{ marginBottom: 0 }}
        />
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default NotFoundPage

export const query = graphql`
  query NotFound($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...TryXpsModalTranslations
        not_found
        not_found_message
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    }
  }
`
