import React from "react"

export const CollectionsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_410" data-name="Group 410" transform="translate(138 22045)">
        <rect
          id="Rectangle_251"
          data-name="Rectangle 251"
          width="60"
          height="60"
          transform="translate(-138 -22045)"
          fill="none"
        />
        <g id="G_300" transform="translate(-141.331 -22090)">
          <path
            id="Path_330"
            data-name="Path 330"
            d="M44.24,68.94v-.125a2.638,2.638,0,0,0-1.648-2.448A2.475,2.475,0,0,0,40.126,64H26.536a2.476,2.476,0,0,0-2.47,2.4A2.638,2.638,0,0,0,22.5,68.815v.058a2.753,2.753,0,0,0-1.173,2.25v10.3a2.762,2.762,0,0,0,2.759,2.759H42.572a2.762,2.762,0,0,0,2.759-2.759v-10.3A2.746,2.746,0,0,0,44.24,68.94Zm-17.7-3.849H40.126a1.387,1.387,0,0,1,1.349,1.077H25.187A1.387,1.387,0,0,1,26.536,65.091ZM23.78,68.077a1.556,1.556,0,0,1,1.37-.818H41.594a1.543,1.543,0,0,1,1.5,1.157,2.753,2.753,0,0,0-.523-.053H24.09a2.733,2.733,0,0,0-.439.044,1.535,1.535,0,0,1,.129-.331ZM44.24,81.423a1.67,1.67,0,0,1-1.668,1.668H24.09a1.67,1.67,0,0,1-1.668-1.668v-10.3a1.67,1.67,0,0,1,1.668-1.668H42.572a1.67,1.67,0,0,1,1.668,1.668Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.4"
          />
          <path
            id="Path_331"
            data-name="Path 331"
            d="M193.106,219.627l-4.909-2.834a1.636,1.636,0,0,0-2.455,1.417v5.668A1.633,1.633,0,0,0,188.2,225.3l4.909-2.834a1.636,1.636,0,0,0,0-2.834Zm-.545,1.89-4.909,2.834a.545.545,0,0,1-.818-.472V218.21a.536.536,0,0,1,.273-.472.562.562,0,0,1,.276-.075.533.533,0,0,1,.27.075l4.909,2.834a.545.545,0,0,1,0,.945Z"
            transform="translate(-156.004 -144.772)"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.4"
          />
        </g>
      </g>
    </svg>
  )
}

export const CommunicationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_407" data-name="Group 407" transform="translate(138 22240)">
        <rect
          id="Rectangle_241"
          data-name="Rectangle 241"
          width="60"
          height="60"
          transform="translate(-138 -22240)"
          fill="none"
        />
        <path
          id="Path_324"
          data-name="Path 324"
          d="M7.316,16.737H8.368V15.684H7.316Zm0,5.263H6.263v2.541l1.8-1.8Zm5.263-5.263V15.684h-.436l-.308.308ZM3.105,6.211A2.105,2.105,0,0,1,5.211,4.105V2A4.211,4.211,0,0,0,1,6.211Zm0,7.368V6.211H1v7.368Zm2.105,2.105a2.105,2.105,0,0,1-2.105-2.105H1a4.211,4.211,0,0,0,4.211,4.211Zm2.105,0H5.211v2.105H7.316ZM6.263,16.737V22H8.368V16.737Zm1.8,6.007,5.263-5.263-1.489-1.489L6.571,21.256Zm8.729-7.06H12.579v2.105h4.211Zm2.105-2.105a2.105,2.105,0,0,1-2.105,2.105v2.105A4.211,4.211,0,0,0,21,13.579Zm0-7.368v7.368H21V6.211ZM16.789,4.105a2.105,2.105,0,0,1,2.105,2.105H21A4.211,4.211,0,0,0,16.789,2Zm-11.579,0H16.789V2H5.211Z"
          transform="translate(-119 -22221)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const DiagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_408" data-name="Group 408" transform="translate(138 22175)">
        <rect
          id="Rectangle_251"
          data-name="Rectangle 251"
          width="60"
          height="60"
          transform="translate(-138 -22175)"
          fill="none"
        />
        <path
          id="Path_326"
          data-name="Path 326"
          d="M15.889,11.111a1.667,1.667,0,1,0-1.667-1.667A1.709,1.709,0,0,0,15.889,11.111Zm0-2.222a.556.556,0,1,1-.556.556A.525.525,0,0,1,15.889,8.889Zm-2.222,4.778.778.778.889-.889v2.667a2.7,2.7,0,0,1-2.667,2.667h-3a1.671,1.671,0,0,0-1.556-1.111,1.667,1.667,0,1,0,0,3.333A1.569,1.569,0,0,0,9.667,20h3a3.747,3.747,0,0,0,3.778-3.778V13.556l.889.889.778-.778-2.222-2.222ZM8.111,20a.556.556,0,1,1,.556-.556A.525.525,0,0,1,8.111,20ZM19.778,2.222H17.556a1.05,1.05,0,0,0-1.111-1.111H13.889a2.161,2.161,0,0,0-3.778,0H7.556A1.05,1.05,0,0,0,6.444,2.222H4.222A2.229,2.229,0,0,0,2,4.444V22.222a2.229,2.229,0,0,0,2.222,2.222H19.778A2.229,2.229,0,0,0,22,22.222V4.444A2.229,2.229,0,0,0,19.778,2.222Zm-12.222,0h3.222l.333-.556A.88.88,0,0,1,12,1.111a1.167,1.167,0,0,1,1,.556l.222.556h3.222V4.444H7.556Zm13.333,20a1.05,1.05,0,0,1-1.111,1.111H4.222a1.05,1.05,0,0,1-1.111-1.111V4.444A1.05,1.05,0,0,1,4.222,3.333H6.444V4.444A1.05,1.05,0,0,0,7.556,5.556h8.889a1.05,1.05,0,0,0,1.111-1.111V3.333h2.222a1.05,1.05,0,0,1,1.111,1.111ZM7,14.778l1.111-1.111,1.111,1.111L10,14,8.889,12.889,10,11.778l-.778-.667L8.111,12.222,7,11.111l-.778.778L7.333,13,6.222,14.111Z"
          transform="translate(-120 -22157)"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="0.2"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  )
}

export const InjuryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_411" data-name="Group 411" transform="translate(138 21980)">
        <rect
          id="Rectangle_249"
          data-name="Rectangle 249"
          width="60"
          height="60"
          transform="translate(-138 -21980)"
          fill="none"
        />
        <g
          id="Group_404"
          data-name="Group 404"
          transform="translate(-120.006 -21961)"
        >
          <path
            id="Path_332"
            data-name="Path 332"
            d="M.511,10.54A7.724,7.724,0,0,1,.182,9.449H6.7L8.2,5.438l.511-1.362.511,1.362,2.763,7.367,1.126-3,.133-.354h6.38V10.54H14l-1.5,4.011-.511,1.362-.511-1.362L8.713,7.184l-1.126,3-.133.354H.511Z"
            transform="translate(0.016 0.281)"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.5"
            fill-rule="evenodd"
          />
          <path
            id="Path_333"
            data-name="Path 333"
            d="M6.858,1A6.859,6.859,0,0,1,12,3.318a6.862,6.862,0,0,1,12,4.529,8.164,8.164,0,0,1-2.527,5.991L12,22.824,2.526,13.838a8.766,8.766,0,0,1-2-3.017H1.71a7.909,7.909,0,0,0,1.581,2.238L12,21.32l8.711-8.261a7.072,7.072,0,0,0,2.2-5.212A5.77,5.77,0,0,0,12.82,4.041L12,4.965l-.817-.925A5.771,5.771,0,0,0,1.1,7.547H.006A6.854,6.854,0,0,1,6.858,1Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.5"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}

export const MonitoringIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_406" data-name="Group 406" transform="translate(138 22305)">
        <rect
          id="Rectangle_247"
          data-name="Rectangle 247"
          width="60"
          height="60"
          transform="translate(-138 -22305)"
          fill="none"
        />
        <g
          id="Group_402"
          data-name="Group 402"
          transform="translate(-121.5 -22287.5)"
        >
          <path
            id="Path_317"
            data-name="Path 317"
            d="M1.5,5.5a2,2,0,0,1,2-2h20a2,2,0,0,1,2,2V20.167a2,2,0,0,1-2,2H3.5a2,2,0,0,1-2-2Z"
            transform="translate(0 0)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.4"
          />
          <path
            id="Path_318"
            data-name="Path 318"
            d="M10.5,15.833,13.167,10.5"
            transform="translate(3 2.333)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.4"
          />
          <path
            id="Path_319"
            data-name="Path 319"
            d="M4.5,14.5a8,8,0,1,1,16,0"
            transform="translate(1 1)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.4"
          />
          <path
            id="Path_320"
            data-name="Path 320"
            d="M15,12.5h2.667"
            transform="translate(4.5 3)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.4"
          />
          <path
            id="Path_321"
            data-name="Path 321"
            d="M4,12.5H6.667"
            transform="translate(0.833 3)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.4"
          />
          <path
            id="Path_322"
            data-name="Path 322"
            d="M10.5,6.5v2"
            transform="translate(3 1)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.4"
          />
        </g>
      </g>
    </svg>
  )
}

export const PlanningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_405" data-name="Group 405" transform="translate(138 22370)">
        <rect
          id="Rectangle_243"
          data-name="Rectangle 243"
          width="60"
          height="60"
          transform="translate(-138 -22370)"
          fill="none"
        />
        <path
          id="Path_316"
          data-name="Path 316"
          d="M9.059,2H7.647V3.412H4.824A2.824,2.824,0,0,0,2,6.235V21.765a2.824,2.824,0,0,0,2.824,2.824H23.176A2.823,2.823,0,0,0,26,21.765V6.235a2.824,2.824,0,0,0-2.824-2.824H20.353V2H18.941V3.412H14.706V2H13.294V3.412H9.059ZM7.647,4.824V6.235H9.059V4.824h4.235V6.235h1.412V4.824h4.235V6.235h1.412V4.824h2.824a1.412,1.412,0,0,1,1.412,1.412V21.765a1.412,1.412,0,0,1-1.412,1.412H4.824a1.412,1.412,0,0,1-1.412-1.412V6.235A1.412,1.412,0,0,1,4.824,4.824Zm1.412,7.059v1.412H7.647V11.882Zm11.294,1.412V11.882H18.941v1.412Zm-5.647-1.412v1.412H13.294V11.882ZM9.059,18.941V17.529H7.647v1.412Zm11.294-1.412v1.412H18.941V17.529Zm-5.647,1.412V17.529H13.294v1.412Z"
          transform="translate(-122 -22353)"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  )
}

export const VideoAnalysisIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_409" data-name="Group 409" transform="translate(138 22110)">
        <rect
          id="Rectangle_245"
          data-name="Rectangle 245"
          width="60"
          height="60"
          transform="translate(-138 -22110)"
          fill="none"
        />
        <g
          id="Group_403"
          data-name="Group 403"
          transform="translate(-120.5 -22091.5)"
        >
          <path
            id="Path_327"
            data-name="Path 327"
            d="M8.5,5.5v8.727l7.636-4.364Z"
            transform="translate(0.727 0.273)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
          />
          <path
            id="Path_328"
            data-name="Path 328"
            d="M.5,4.136A1.636,1.636,0,0,1,2.136,2.5H22.864A1.636,1.636,0,0,1,24.5,4.136v12a1.636,1.636,0,0,1-1.636,1.636H2.136A1.636,1.636,0,0,1,.5,16.136Z"
            transform="translate(0 0)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
          />
          <path
            id="Path_329"
            data-name="Path 329"
            d="M12,16.5v3.273m-6,0H18"
            transform="translate(0.5 1.273)"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  )
}

export const ThumbUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_419" data-name="Group 419" transform="translate(-1044 -253)">
        <rect
          id="Rectangle_247"
          data-name="Rectangle 247"
          width="60"
          height="60"
          transform="translate(1044 253)"
          fill="none"
        />
        <g id="social_media" transform="translate(1055.325 271)">
          <path
            id="Path_337"
            data-name="Path 337"
            d="M29.955,16.785a3.067,3.067,0,0,0,.718-2.05A3.149,3.149,0,0,0,30,12.959a2.759,2.759,0,0,0-.656-3.175c-.821-.749-2.215-1.084-4.146-.991a16.2,16.2,0,0,0-2.54.325h-.005c-.258.046-.532.1-.811.165a9.007,9.007,0,0,1,.645-3c.723-2.2.682-3.883-.134-5.008A3.282,3.282,0,0,0,19.727,0a1.3,1.3,0,0,0-1,.454A3.473,3.473,0,0,0,18.3,2.917c-.682,1.828-2.592,6.309-4.208,7.553a.553.553,0,0,0-.083.072,5.546,5.546,0,0,0-1.012,1.518,2.131,2.131,0,0,0-1.022-.258H8.823A2.149,2.149,0,0,0,6.675,13.95v8.39a2.149,2.149,0,0,0,2.148,2.148h3.149a2.143,2.143,0,0,0,1.239-.392l1.213.145a51.982,51.982,0,0,0,6.882.377c.614.046,1.193.072,1.73.072a11.584,11.584,0,0,0,2.4-.217,4.49,4.49,0,0,0,3.206-1.993,3.16,3.16,0,0,0,.351-1.977,3.293,3.293,0,0,0,1.172-2.68A3.488,3.488,0,0,0,29.955,16.785ZM8.823,23.094a.755.755,0,0,1-.754-.754V13.945a.755.755,0,0,1,.754-.754h3.149a.755.755,0,0,1,.754.754v8.39a.755.755,0,0,1-.754.754H8.823Zm19.81-6.913a.694.694,0,0,0-.093.842,2.148,2.148,0,0,1,.237.862,2.066,2.066,0,0,1-.971,1.786.7.7,0,0,0-.237.8A1.823,1.823,0,0,1,27.43,21.8a3.23,3.23,0,0,1-2.282,1.311,13.6,13.6,0,0,1-3.764.114h-.072a50.962,50.962,0,0,1-6.712-.367h-.005l-.521-.062a2.187,2.187,0,0,0,.046-.454V13.945a2.184,2.184,0,0,0-.1-.64,4.315,4.315,0,0,1,.96-1.771C17.3,9.7,19.567,3.5,19.665,3.227a.654.654,0,0,0,.031-.346,3.226,3.226,0,0,1,.067-1.5,1.852,1.852,0,0,1,1.456.7c.527.728.506,2.029-.062,3.753-.867,2.628-.94,4.012-.253,4.621a1.125,1.125,0,0,0,1.126.2c.315-.072.614-.134.9-.181L23,10.46c1.585-.346,4.425-.558,5.411.341a1.485,1.485,0,0,1,.176,1.884.69.69,0,0,0,.124.9,1.956,1.956,0,0,1,.573,1.2A1.917,1.917,0,0,1,28.633,16.181Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  )
}

export const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_418" data-name="Group 418" transform="translate(-1191 -106)">
        <rect
          id="Rectangle_241"
          data-name="Rectangle 241"
          width="60"
          height="60"
          transform="translate(1191 106)"
          fill="none"
        />
        <g id="download" transform="translate(1205 120)">
          <path
            id="Path_336"
            data-name="Path 336"
            d="M6,21v1.25A3.75,3.75,0,0,0,9.75,26h12.5A3.75,3.75,0,0,0,26,22.25V21m-5-5-5,5m0,0-5-5m5,5V6"
            transform="translate(0)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  )
}

export const OpenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_413" data-name="Group 413" transform="translate(-1044 -106)">
        <rect
          id="Rectangle_247"
          data-name="Rectangle 247"
          width="60"
          height="60"
          transform="translate(1044 106)"
          fill="none"
        />
        <g id="external" transform="translate(1061.625 120.5)">
          <path
            id="Path_335"
            data-name="Path 335"
            d="M13.055,8.9H3.375V26.5h17.6V16.82M16.575,4.5h8.8v8.8m0-8.8L13.055,16.82"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  )
}

export const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <g id="Group_415" data-name="Group 415" transform="translate(-897 -106)">
        <rect
          id="Rectangle_243"
          data-name="Rectangle 243"
          width="60"
          height="60"
          transform="translate(897 106)"
          fill="none"
        />
        <g id="support" transform="translate(910.5 119.5)">
          <path
            id="Path_334"
            data-name="Path 334"
            d="M24.985,8.015l-4.715,4.715m0,7.541,4.715,4.715M12.729,12.729,8.015,8.015m4.715,12.256L8.015,24.985M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Zm-6.667,0A5.333,5.333,0,1,1,16.5,11.167,5.333,5.333,0,0,1,21.833,16.5Z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  )
}

export const BasketballIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.151"
    height="34.15"
    viewBox="0 0 34.151 34.15"
  >
    <path
      id="basketball"
      d="M14.75,2.25a12.5,12.5,0,1,0,12.5,12.5,12.5,12.5,0,0,0-12.5-12.5ZM25.42,13.857H20.143a12.865,12.865,0,0,1,2.444-6.387,10.66,10.66,0,0,1,2.833,6.387Zm-9.777,0V4.08a10.641,10.641,0,0,1,5.613,2.177,14.522,14.522,0,0,0-2.9,7.6Zm-1.786,0H11.143a14.522,14.522,0,0,0-2.9-7.6,10.641,10.641,0,0,1,5.613-2.176Zm0,1.786V25.42a10.641,10.641,0,0,1-5.613-2.177,14.522,14.522,0,0,0,2.9-7.6Zm1.786,0h2.714a14.522,14.522,0,0,0,2.9,7.6,10.641,10.641,0,0,1-5.614,2.176ZM6.911,7.471a12.865,12.865,0,0,1,2.446,6.387H4.081a10.66,10.66,0,0,1,2.83-6.387ZM4.08,15.643H9.357a12.865,12.865,0,0,1-2.444,6.387A10.66,10.66,0,0,1,4.08,15.643Zm18.509,6.387a12.865,12.865,0,0,1-2.446-6.387h5.275a10.66,10.66,0,0,1-2.83,6.387Z"
      transform="translate(22.474 -3.074) rotate(60)"
      fill="currentColor"
    />
  </svg>
)

export const FieldHockeyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g id="Group_266" data-name="Group 266" transform="translate(-2588 -895)">
      <g
        id="Ellipse_30"
        data-name="Ellipse 30"
        transform="translate(2588 895)"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
      >
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
        <circle cx="12.5" cy="12.5" r="11.5" fill="none" />
      </g>
      <g
        id="Ellipse_31"
        data-name="Ellipse 31"
        transform="translate(2592 899)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="1.5"
        stroke-dasharray="0 6"
      >
        <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
        <circle cx="8.5" cy="8.5" r="7.75" fill="none" />
      </g>
      <g
        id="Ellipse_32"
        data-name="Ellipse 32"
        transform="matrix(0.966, -0.259, 0.259, 0.966, 2593.764, 903.611)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="1.5"
        stroke-dasharray="0 5"
      >
        <circle cx="5.5" cy="5.5" r="5.5" stroke="none" />
        <circle cx="5.5" cy="5.5" r="4.75" fill="none" />
      </g>
    </g>
  </svg>
)

export const FloorballIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.5"
    height="25.5"
    viewBox="0 0 25.5 25.5"
  >
    <g id="floorball" transform="translate(0.25 0.25)">
      <path
        id="Path_250"
        data-name="Path 250"
        d="M183.713,186.094a2.381,2.381,0,1,1,2.381-2.381A2.381,2.381,0,0,1,183.713,186.094Zm0-3.571a1.19,1.19,0,1,0,1.19,1.19A1.19,1.19,0,0,0,183.713,182.523Zm0,0"
        transform="translate(-171.213 -171.213)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_251"
        data-name="Path 251"
        d="M98.381,100.762a2.381,2.381,0,1,1,2.381-2.381A2.381,2.381,0,0,1,98.381,100.762Zm0-3.571a1.191,1.191,0,1,0,1.19,1.191A1.191,1.191,0,0,0,98.381,97.19Zm0,0"
        transform="translate(-90.643 -90.643)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_252"
        data-name="Path 252"
        d="M269.049,100.762a2.381,2.381,0,1,1,2.381-2.381A2.381,2.381,0,0,1,269.049,100.762Zm0-3.571a1.191,1.191,0,1,0,1.191,1.191A1.19,1.19,0,0,0,269.049,97.19Zm0,0"
        transform="translate(-251.787 -90.643)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_253"
        data-name="Path 253"
        d="M269.049,271.43a2.381,2.381,0,1,1,2.381-2.381A2.381,2.381,0,0,1,269.049,271.43Zm0-3.571a1.191,1.191,0,1,0,1.191,1.19A1.19,1.19,0,0,0,269.049,267.858Zm0,0"
        transform="translate(-251.787 -251.787)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_254"
        data-name="Path 254"
        d="M98.381,271.43a2.381,2.381,0,1,1,2.381-2.381A2.381,2.381,0,0,1,98.381,271.43Zm0-3.571a1.191,1.191,0,1,0,1.19,1.19A1.19,1.19,0,0,0,98.381,267.858Zm0,0"
        transform="translate(-90.643 -251.787)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_255"
        data-name="Path 255"
        d="M12.5,25A12.5,12.5,0,1,1,25,12.5,12.518,12.518,0,0,1,12.5,25Zm0-23.81A11.31,11.31,0,1,0,23.81,12.5,11.324,11.324,0,0,0,12.5,1.19Zm0,0"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_256"
        data-name="Path 256"
        d="M186.074,386.879l-1.146-.313a1.191,1.191,0,1,0-2.3,0l-1.146.313a2.381,2.381,0,1,1,4.591,0Zm0,0"
        transform="translate(-171.275 -362.444)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_257"
        data-name="Path 257"
        d="M183.686,13.139a2.381,2.381,0,0,1-2.3-3.006l1.146.313a1.191,1.191,0,1,0,2.3,0l1.146-.313a2.381,2.381,0,0,1-2.292,3.006Zm0,0"
        transform="translate(-171.186 -9.567)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_258"
        data-name="Path 258"
        d="M386.381,186.093a2.381,2.381,0,1,1,.632-4.68l-.313,1.146a1.21,1.21,0,0,0-.312-.044,1.19,1.19,0,1,0,.312,2.336l.313,1.146A2.381,2.381,0,0,1,386.381,186.093Zm0,0"
        transform="translate(-362.571 -171.212)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
      <path
        id="Path_259"
        data-name="Path 259"
        d="M10.758,186.094a2.381,2.381,0,0,1-.625-.082l.313-1.146a1.213,1.213,0,0,0,.312.044,1.19,1.19,0,0,0,1.191-1.19,1.205,1.205,0,0,0-1.5-1.146l-.313-1.146a2.375,2.375,0,1,1,.625,4.665Zm0,0"
        transform="translate(-9.567 -171.213)"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="0.5"
      />
    </g>
  </svg>
)

export const HandballIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <path
      id="Path_247"
      data-name="Path 247"
      d="M303.3,359.847a10.617,10.617,0,0,1-3.592,0l1.8-1.347Zm1.266-.3L302.1,357.7l-.1-.075v-2.75l.1-.075,4-3,.093-.07,3.332,1,.017.058.741,2.469A10.522,10.522,0,0,1,304.562,359.546Zm6.4-5.495a10.422,10.422,0,0,0,.841-2.53l-1.306.979Zm1.016-3.912q.02-.316.019-.639a10.457,10.457,0,0,0-2.253-6.5h-2.514l-2.275,2.713,1.5,5,.017.057,3.332,1,.093-.07ZM308.848,342a10.513,10.513,0,0,0-1.767-1.395L307.5,342Zm-3.012-2.066.7,2.339L304.251,345h-5.5l-2.287-2.727.7-2.339a10.532,10.532,0,0,1,8.672,0Zm-9.917.671A10.513,10.513,0,0,0,294.152,342H295.5ZM293.253,343A10.457,10.457,0,0,0,291,349.5q0,.322.019.639L293.1,351.7l.093.07,3.332-1,.017-.057,1.5-5L295.767,343h-2.514Zm-2.059,8.521a10.422,10.422,0,0,0,.841,2.53l.465-1.551Zm1.523,3.735a10.522,10.522,0,0,0,5.721,4.29L300.9,357.7l.1-.075v-2.75l-.1-.075-4-3-.093-.07-3.332,1-.017.058ZM314,349.5A12.5,12.5,0,1,1,301.5,337,12.5,12.5,0,0,1,314,349.5Zm-15.031-3.4L297.5,351l4,3,4-3-1.469-4.9-.123-.1h-4.816Z"
      transform="translate(-289 -337)"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
)

export const IceHockeyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="18"
    viewBox="0 0 28 18"
  >
    <path
      id="hockey-puck-solid"
      d="M16.25,7.875c-6.957,0-14,1.718-14,5v8c0,3.282,7.043,5,14,5s14-1.718,14-5v-8C30.25,9.593,23.207,7.875,16.25,7.875Zm0,2c7.444,0,12,1.942,12,3,0,.926-3.489,2.528-9.348,2.914q-1.324.087-2.652.086c-7.444,0-12-1.942-12-3S8.806,9.875,16.25,9.875Zm-12,5.707c.06.035.134.064.195.1a9.769,9.769,0,0,0,1.014.486c.346.144.706.282,1.094.408a22.558,22.558,0,0,0,2.681.664c.289.055.587.1.885.147.85.137,1.726.247,2.633.324,1.15.1,2.319.166,3.5.166s2.349-.068,3.5-.166q1.322-.112,2.633-.324c.3-.047.6-.092.885-.147a22.687,22.687,0,0,0,2.681-.664,14.457,14.457,0,0,0,1.395-.539q.364-.163.713-.355c.061-.034.136-.063.195-.1v5.292c0,1.058-4.556,3-12,3s-12-1.942-12-3Z"
      transform="translate(-2.25 -7.875)"
      fill="currentColor"
    />
  </svg>
)

export const PerformanceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36.77"
    height="23.293"
    viewBox="0 0 36.77 23.293"
  >
    <g id="dumbbell" transform="translate(18.381 -6.742) rotate(45)">
      <g id="Group_276" data-name="Group 276" transform="translate(0.005 0)">
        <g id="Group_275" data-name="Group 275">
          <path
            id="Path_265"
            data-name="Path 265"
            d="M214.874,7.175l-3.34-3.34.441-.441.992.993a.879.879,0,0,0,1.243-1.244L212.6,1.528a.879.879,0,0,0-1.243,0L210.29,2.59,208.565.865A2.679,2.679,0,0,0,204.791.7l-.631.631a2.789,2.789,0,0,0-3.348.449l-.108.108a2.788,2.788,0,0,0,0,3.939l9.208,9.209a2.787,2.787,0,0,0,3.937,0l.108-.108a2.789,2.789,0,0,0,.45-3.348l.632-.632A2.679,2.679,0,0,0,214.874,7.175Zm-2.159,6.509-.108.108a1.026,1.026,0,0,1-1.451,0l-9.209-9.209a1.031,1.031,0,0,1,0-1.451l.108-.108a1.054,1.054,0,0,1,1.45,0l2.082,2.082a.879.879,0,0,0,1.244-1.244L205.473,2.5l.561-.56a.946.946,0,0,1,1.287.165l6.309,6.31a.946.946,0,0,1,.165,1.287l-.56.56-4.593-4.593A.879.879,0,0,0,207.4,6.916l5.21,5.21,0,0,0,0,.1.1a1.031,1.031,0,0,1,0,1.45Z"
            transform="translate(-189.739 0)"
            fill="currentColor"
          />
          <path
            id="Path_266"
            data-name="Path 266"
            d="M14.976,190.134l-4.048,4.048-.979-.979L14,189.154a.879.879,0,0,0-1.244-1.244L8.7,191.96l-2.872-2.872a2.788,2.788,0,0,0-3.938,0l-.108.108a2.79,2.79,0,0,0-.449,3.349l-.631.632a2.679,2.679,0,0,0,.165,3.773L2.6,198.675l-1.063,1.063a.879.879,0,0,0,0,1.244L3.147,202.6a.879.879,0,0,0,1.243-1.244l-.992-.992.441-.441,3.341,3.341a2.9,2.9,0,0,0,2.038.865,2.428,2.428,0,0,0,1.736-.7l.631-.63a2.789,2.789,0,0,0,3.349-.45l.108-.108a2.788,2.788,0,0,0,0-3.938l-2.87-2.87,4.048-4.048a.879.879,0,0,0-1.243-1.244ZM14.1,200.267a1.018,1.018,0,0,1-.3.725l-.108.108a1.056,1.056,0,0,1-1.451,0l-.1-.1h0l-5.215-5.214a.879.879,0,0,0-1.243,1.244l4.592,4.592-.561.56a.947.947,0,0,1-1.287-.165l-6.309-6.309a.946.946,0,0,1-.164-1.287l.561-.561,1.358,1.358a.879.879,0,1,0,1.244-1.244L3.03,191.89a1.03,1.03,0,0,1,0-1.45l.108-.108a1.055,1.055,0,0,1,1.451,0l5.71,5.71.006.007.007.006,3.486,3.487A1.018,1.018,0,0,1,14.1,200.267Z"
            transform="translate(-0.005 -178.124)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const SoccerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <path
      id="Path_244"
      data-name="Path 244"
      d="M146.154,54.785A9.59,9.59,0,0,0,146,56.5a9.457,9.457,0,0,0,1.974,5.8q0-.041.009-.082l.207-1.41a.5.5,0,0,1,.628-.409l2.528.7a1,1,0,0,1,.605.472l1.29,2.284a.5.5,0,0,1-.245.708l-1.318.542a1.017,1.017,0,0,1-.122.041,9.522,9.522,0,0,0,8.232-.165,1,1,0,0,1-.1-.034l-1.317-.542a.5.5,0,0,1-.245-.708l1.29-2.284a1,1,0,0,1,.6-.472l2.528-.7a.5.5,0,0,1,.628.409l.176,1.2a9.523,9.523,0,0,0,1.493-7.045l-.542.736a.5.5,0,0,1-.747.066l-1.9-1.8a1,1,0,0,1-.312-.7l-.066-2.622a.5.5,0,0,1,.549-.51l.627.062a9.495,9.495,0,0,0-4.337-2.662l.433.761a.5.5,0,0,1-.243.709l-2.422,1.006a1,1,0,0,1-.767,0l-2.423-1.006a.5.5,0,0,1-.243-.709l.433-.761a9.5,9.5,0,0,0-4.338,2.663l.641-.063a.5.5,0,0,1,.549.51L149.67,53.1a1,1,0,0,1-.312.7l-1.9,1.8a.5.5,0,0,1-.746-.066ZM144,56.5A11.5,11.5,0,1,1,155.5,68,11.5,11.5,0,0,1,144,56.5Zm12.088-3.073,2.153,1.564a1,1,0,0,1,.363,1.118l-.822,2.531a1,1,0,0,1-.951.691h-2.662a1,1,0,0,1-.951-.691l-.822-2.531a1,1,0,0,1,.363-1.118l2.153-1.564A1,1,0,0,1,156.088,53.427Z"
      transform="translate(-144 -45)"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
)

export const TennisIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.25"
    height="27.25"
    viewBox="0 0 27.25 27.25"
  >
    <g id="tennisball-outline" transform="translate(-2.25 -2.25)">
      <path
        id="Path_260"
        data-name="Path 260"
        d="M28.375,15.875a12.5,12.5,0,1,1-12.5-12.5A12.5,12.5,0,0,1,28.375,15.875Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#63707a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.25"
      />
      <path
        id="Path_261"
        data-name="Path 261"
        d="M30.182,16.824c-.306.022-.495.033-.807.033a12.5,12.5,0,0,1-12.5-12.5c0-.323.012-.522.036-.841"
        transform="translate(-1.962 -0.02)"
        fill="none"
        stroke="#63707a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.25"
      />
      <path
        id="Path_262"
        data-name="Path 262"
        d="M3.491,16.908s.552-.033.862-.033a12.5,12.5,0,0,1,12.5,12.5c0,.323-.037.841-.037.841"
        transform="translate(-0.017 -1.962)"
        fill="none"
        stroke="#63707a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.25"
      />
    </g>
  </svg>
)

export const VolleyballIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.16 2.0847C6.10117 2.72907 2.16652 6.97319 2.00515 12.168C3.29057 12.6811 4.6408 12.9879 6.00371 13.0884C6.05165 10.4494 6.56542 8.12729 7.62094 6.14042C8.4607 4.5597 9.63448 3.20812 11.16 2.0847ZM3.22781 17.4316C2.55449 16.1682 2.13181 14.7511 2.02612 13.2465C5.37528 14.4712 9.09406 14.4154 12.4095 13.0791C13.9492 14.3961 15.0141 15.5136 15.8495 16.7674C15.8518 16.7707 15.854 16.774 15.8562 16.7774C11.7705 18.8819 6.91429 18.9269 3.22781 17.4316ZM16.3785 17.633C12.4583 19.6756 7.86859 19.9335 4.09645 18.7963C6.01191 21.3487 9.06312 23 12.5 23C14.5019 23 16.373 22.4398 17.9649 21.4675C17.4681 19.9195 16.9757 18.7009 16.3785 17.633ZM18.8259 20.8813C19.8921 20.0753 20.7997 19.0705 21.4935 17.9222C21.1509 13.1767 18.2649 10.0141 14.5189 8.07076C13.7863 9.33439 13.2593 10.7729 13.0326 12.2961C14.625 13.6558 15.7717 14.8471 16.6817 16.2129C17.5602 17.5312 18.2037 18.9903 18.8259 20.8813ZM17.6927 3.37183C14.8717 5.0478 12.6057 8.3741 12.0435 12.1484C10.4322 12.7991 8.71774 13.1246 7.00322 13.125C7.04692 10.5962 7.53661 8.43065 8.50406 6.60958C9.49458 4.74507 11.0007 3.21347 13.1009 2.01691C14.7647 2.11078 16.3249 2.592 17.6927 3.37183ZM15.0582 7.22428C18.5749 9.07019 21.4614 12.0049 22.2889 16.3062C22.7481 15.1261 23 13.8425 23 12.5C23 8.99642 21.284 5.89362 18.6467 3.98621C17.295 4.68053 16.0445 5.81171 15.0582 7.22428ZM0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5Z"
      fill="currentColor"
    />
  </svg>
)

export const ArrowRightBlue = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 38 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={1.5}
  >
    <g transform="matrix(0.884905,0,0,0.884905,-73.543,-1.00365)">
      <path
        d="M117.665,2.626L124.649,9.61L117.665,16.593"
        fill="none"
        stroke="#0073D9"
        strokeWidth={2.59}
      />
    </g>
    <g transform="matrix(1.02235,0,0,1.02235,-82.8471,-2.72346)">
      <path
        d="M82.229,10L116.992,10"
        fill="none"
        stroke="#0073D9"
        strokeWidth={2.24}
      />
    </g>
  </svg>
)